




















import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';
import { category as categoryData } from '@/js/data/category';
import { dynamicImageUrl } from '@/js/lib/helper/url';
import { getSameCategoryItems, type SameCategoryItem } from '@/js/lib/api/item';

const itemImageUrl = (imagePath: string) => dynamicImageUrl(imagePath, 310, 310);

export default defineComponent({
  props: {
    categoryId: {
      type: Number,
      default: undefined,
    },
    isVintage: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Number,
      default: undefined,
    },
  },
  setup(props) {
    const rootEl = ref<HTMLDivElement>();
    const items = ref<SameCategoryItem[]>([]);
    const category = categoryData({ categoryId: props.categoryId });
    const categoryName = category?.name || '';
    const linkToCategory = computed(() => {
      let path = '/listing';
      if (category) {
        if (category.parentCategoryId) {
          const parent = categoryData({ categoryId: category.parentCategoryId });
          path = `/category/${parent.slug}/${category.slug}`;
        } else {
          path = `/category/${category.slug}`;
        }
      }

      if (props.isVintage) {
        path += '?vintage=on';
      }

      return path;
    });
    const loadItems = async () => {
      const data = await getSameCategoryItems({ categoryId: props.categoryId, price: props.price, isVintage: props.isVintage });
      items.value = data.slice(0, 18);
    };

    onMounted(() => {
      if ('IntersectionObserver' in window) {
        const rootElObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              loadItems();
              rootElObserver.unobserve(rootEl.value);
            }
          });
        });
        rootElObserver.observe(rootEl.value);
      }
    });

    return {
      rootEl,
      items,
      categoryName,
      linkToCategory,
      itemImageUrl,
    };
  },
});
